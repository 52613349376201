.responsive {
    width: auto;
    max-height: 50vh;
  }
  .bg-2 { 
   
    color: #ffffff;
    height:30vh;
  }
  .bg-1{
    background-color: rgb(1, 16, 70);
    /* height:60vh; */
  }
  .upcoming{
    height:50vh;
  }
  .play-button-1{
    position:absolute;
    top:45%;
    left:45%;
    height:50px;
    width: 50px;
    z-index: 5;
    opacity: 0.6;
    
  }
  .position-relative:hover .play-button-1{
    opacity: 1;
  }
  .position-relative:hover .img-thumbnail{

    opacity: 0.7;
  }
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  .position-absolute{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container1 {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; 
    /* 16:9 Aspect Ratio */
  }
  .font-lato {
    font-family: 'Lato', sans-serif;
    
  }
  .contactusbg {
    position:relative;
    text-align:center;
    background-image: url("/public/image/flute1.jpg");
    background-repeat: no-repeat;
    background-position: center;
   background-color: rgb(175, 244, 198);
   background-size: cover;
    height:25vh;
    /* background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%); */
    color:rgb(255, 255, 255);
    /* color: rgb(122, 235, 237); */
  }
  .polaroid {
    width: 450px;
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    text-align: center;
    overflow-x: hidden;
  }
  .coursesbg {
    position:relative;
    text-align:center;
    background-image: url("https://eternalreligion.org/wp-content/uploads/2019/06/The-Vedas.jpg");
    background-repeat: no-repeat;
    background-position: center;
   background-color: rgb(249, 221, 167);
   /* background-size: cover; */
    height:25vh;
   
    color:rgb(255, 255, 255);
    /* color: rgb(122, 235, 237); */
  }
  
  .slide-effect {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
   .slideDown, .slideUp {
    position: relative;
    font-family: tahoma;
    font-size: 50px;
    opacity:0;
  }
  
  
  
  .slideDown {
    top:-40px;
    left:5px;
    animation: slideDown ease .4s forwards .6s;
  }
  
  .slideUp {
    top:40px;
    left:10px;
    animation: slideUp ease .5s forwards 1.2s;
  }
  
  
  @keyframes slideUp {
    0% {transform: translateY(0);}
    100% {transform: translateY(-40px);opacity:1;}
  }
  
  @keyframes slideDown {
    0% {transform: translateY(0);}
    100% {transform: translateY(40px);opacity:1;}
  }
  
  @media only screen and (max-width: 600px) {
    .slide-effect, .text, .slideDown, .slideUp {
      font-size: 30px;
    }
  }
  @media only screen and (max-width: 305px) {
    .slide-effect, .text, .slideDown, .slideUp {
      font-size: 20px;
    }
  }
  .brown-wallpaper{
    
    background-blend-mode: overlay;
    background-color: rgb(250, 223, 133);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height:35vh;
    color: black !important;
  }
  .vl {
    border-left: 4px solid rgb(0, 17, 128);
    height: 80px;
  }
  .bg-white{
    background-color: #ffffff;
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  }

  .container2 .card {
    position: relative;
    width: 350px;
    height: 700px;
    /* margin: 20px; */
    box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    border-radius: 15px;
    display: flex;
    /* justify-content: center; */

    backdrop-filter: blur(10px);
  }
  
  .container2 .card .content {
    position: relative;
    display: flex;
    justify-content: center;

    flex-direction: column;
    transition: 0.5s;
  }
  
  .container2 .card:hover .content {
    transform: translateY(-15px);
  }
  
  .container2 .card .content .imgBx {
    position: relative;
    /* width: 300px; */
    height: 350px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  
  .container2 .card .content .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .container2 .card .content .contentBx h3 {
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: 20px 0 10px;
    line-height: 1.1em;
  }
  
  .container2 .card .content .contentBx h3 span {
    font-size: 12px;
    font-weight: 300;
    text-transform: initial;
  }