@import url(//fonts.googleapis.com/css?family=Lato:300:400);
@import url(//fonts.googleapis.com/css?family=Tangerine);
body {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: rgb(0, 5, 6);
}
.shadow-text{
  text-shadow: 2px 2px 0px  rgb(0, 0, 0), -2px -2px 0px  rgb(0, 0, 0), 2px -2px 0px  rgb(0, 0, 0), -2px 2px 0px  rgb(0, 0, 0);
}
.logo{
  height: 3rem;
  outline: 1px rgb(73, 148, 210) solid;
  border-radius: 50%;
  background-color: rgb(73, 148, 210);
}
.hover-yellow :hover{
  color: white;
}
/*
                         Wave css
*/
.header h1 {
  font-family: 'Tangerine', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:70px;
}
.tanger{
  font-family: 'Tangerine', sans-serif;
  font-size: 4rem;
}
.lato{
  font-family: "Lato", sans-serif;
  font-size: 4rem;
}
.header p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size:14px;
  color: #333333;
}

.header {
  position:relative;
  text-align:center;
  background-image: url("../public/image/background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%); */
  color:rgb(255, 255, 255);
  /* color: rgb(122, 235, 237); */
}

.deity{
  height: 25rem;
}


.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

/* .content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
} */

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
.font-large{
  font-size: 2rem;
  font-family: 'Lato', sans-serif;
}
.font-medium{
  font-size: 1.2rem;
}
.box-embed{
  background-color: rgb(0, 0, 0);
  color:aliceblue;
}
.bg-pink{
  background-color: #ffe5ff;
}
.carousel-image{
  width:600px;
  /* border: 4px solid black; */
}
.play-button{
  position: relative;
}
.position-relative{
position:relative
}
.play-button{
  position:absolute;
  top:45%;
  left:0%;
  height:50px;
  width: 50px;
  z-index: 5;
  opacity: 0.6;
  
}
.position-relative:hover .play-button{
  opacity: 1;
}
.position-relative:hover .carousel-image{
  /* width:592px; */
  /* border: 4px solid black; */
  opacity: 0.7;
}
.cursor-pointer{
  cursor: pointer;
}
/* .carousel-sizing{
  height:400px;
  width:0px;
  margin:0;
  padding:0;
}
.carousel .control-next.control-arrow:before { border-left: 8px solid #fff; } */

/*Shrinking for mobile*/
.upcoming-event{
  height: 338px;
}
.upcoming-image-div{
  position: relative;
}
.magnify{
  /* position: absolute;
  height: 20px;
  width:20px;
  top: 50%;
  left: 48%;
  z-index: 2; */
  display: none;
  
}
.upcoming-image-div:hover .magnify{
  position: absolute;
  display: inline-block;
  height: 4vw;
  width:4vw;
  top: 42%;
  left: 42%;
  z-index: 2;
  cursor:pointer;
}
.upcoming-image-div:hover .upcoming-event{
  opacity: 0.7;
  cursor:pointer;
}
.uvc-headings-line{
  border-style: solid; border-bottom-width: 5px; border-color: rgb(255, 201, 213); width: 100px; margin: 0px auto;
}

@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .header h2{
    font-size: 1rem;
  }

  .header h1 {
    font-size:30px;
  }
  .deity{
    height: 15rem;
  }
  .font-large{
    font-size: 1rem;
  }
  .arrow-icon{
    display:none;
  }
  .font-medium{
    font-size: 1rem;
  }
  .carousel-image{
    width: 300px;
  }
  .play-button{
    position:absolute;
    top:45%;
    left:0%;
    height:20px;
    width: 20px;
    z-index: 5;
    
  }
  .tanger{
    font-size: 2rem;
  }
  
}
@media (max-width: 1280px) {
  .carousel-image{
    width: 500px;
  }
  .upcoming-event{
    height: 281px;
  }
}
@media (max-width: 1048px) {
  .carousel-image{
    width: 300px;
    height: 169px !important;
  }
  .upcoming-event{
    height: 169px !important;
  }
}
@media (max-width: 1479px) {
  .upcoming-event{
    height: 300px;
  }
  .carousel-image{
    height: 300px;
  }
  
}
.event-wallpaper{
  /* background-color: rgb(239, 239, 113) !important; */
  background-image: url("https://images.unsplash.com/photo-1588345921523-c2dcdb7f1dcd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80");
  /* background-blend-mode: lighten; */
  background-blend-mode: overlay;
  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: black !important;
}
.text-white{
  color: white;
}
.bg-pantone{
  background-color: #f5a8c0;
}
.cards-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 40px;
box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  cursor: pointer;
  transition: 0.4s;
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  border-radius: 40px;
  object-fit: cover;
}

.card .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: -80px;
  height: 40px;
}

.card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
    -5px -5px 30px 15px rgba(0,0,0,0.22);
}

.title-white {
  color: white;
  
}

.title-black {
  color: black;
}
.hw-500{
  height: 634px !important;
  width: 405px !important;
}
.hw-5002{
  height: 634px !important;
  width: 410px !important;
  overflow: scroll;
}
.fb-style{
  border: none;
  overflow: hidden;
  
}
.w-big{
  width: 60%;
}
@media (max-width:768px) {
  .w-big{
    width: 100%;
  }
}